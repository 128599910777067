html {
  /* So we don't have to do weird box-model math */
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  overflow-y: scroll;

  /* This lets us use rem units without thinking to hard. 1rem = 10px */
  font-size: 10px;

  --header-height: 60px;
  --nav-width: 200px;
  --theme-main-color: #ff7849;
}

*,
*:before,
*:after {
  /* Poor mans reset */
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  line-height: 1.35;
}

* {
  font-size: 1.6rem;
}

h1 {
  font-size: 3.2rem;
}

h2 {
  font-size: 2.8rem;
}

h3 {
  font-size: 2.4rem;
}

img {
  width: 100%;
  height: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

input:focus,
button:focus {
  outline: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-size: 1.8rem;
  background: #f1f8ff;
  padding: 2px 6px;
  border-radius: 2px;
}
